// example of currently used rules

import { TrainingLocalesEnum } from '../app/shared/models/educations-model';

export const rules = {
  admission: {
    bloodPressureLabel: 'Do you remember your High/Low Blood Pressure Levels?', // New Century: Who does patient lives with?,
    showSecondEmergencyContact: false, // New Century: true
  },
  employee: {
    mandatoryAttachment: false,
    showWorkPartnersDocumentLogo: false, // impactfulhomecare: false
  },
  training: {
    trainingLocales: [{ full: TrainingLocalesEnum.FullEnglish, short: TrainingLocalesEnum.English }],
  },
};
